<template>
    <div class="rooms-not-found">
        <div class="rooms-not-found__text">
            {{$filters.localize('roomsNotFound')}}
        </div>

        <button class="rooms-not-found__btn" @click="changePrice">
            {{$filters.localize('changePrice')}}
        </button>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
    name: 'v-rooms-not-found',
    methods: {
        ...mapMutations([
            'SET_IS_ROOMS_NOT_FOUND',
        ]),
        changePrice()
        {
            this.SET_IS_ROOMS_NOT_FOUND(false)
        }
    }
}
</script>

<style lang="scss">
.rooms-not-found
{
    padding: 0 15px;
    max-width: 504px;
    margin: 0 auto;
    margin-top: 212px;

    &__text
    {
        height: 80px;
        text-align: center;
        margin-bottom: 30px;
        @include font-set(14, 1.2em, 700);

        @include tablet {text-align: left;}
    }

    &__btn
    {
		background-color: #FFBA00;
		border-radius: 30px;
		@include font-set(14, 50px, 700);
		text-align: center;
		color: #fff;
		text-transform: uppercase;
        padding: 0 48px;
        margin: 0 auto;
        display: block;
    }
}
</style>