<template>
    <div class="close-btn">
		<div class="close-btn__btn"></div>
	</div>
</template>

<script>
export default {
	name: "close-btn"
}
</script>

<style lang="scss">
.close-btn {
	// position: absolute;
	width: 48px;
	height: 48px;
	// top: 0;
	// right: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	// z-index: 100;

	&:hover .close-btn__btn,
	&:active .close-btn__btn
	{
		&::before,
		&::after {background-color: #00A94F;}
	}

	&__btn {
		width: 24px;
        height: 24px;
        border-radius: 50%;
        position: relative;

        &::before,
        &::after
        {
            content: '';
            width: 25px;
            height: 2px;
            background-color: #CDCFD8;
            position: absolute;
            left: 50%;
            top: 50%;
        }
        &::before
        {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after
        {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
	}
}
</style>