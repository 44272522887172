<template>
    <div class="v-rooms-list">
        <div class="corps__rooms-nav">
            <div class="corps__rooms-corps-addr">
                <span class="corps__title-name">{{CURRENT_CORPS.name[LOCALE]}}</span>
                /
                <span class="corps__title-type">{{CURRENT_CORPS.type[LOCALE]}}</span>
            </div>
        </div>

        <div class="corps__rooms-wrapper">
            <v-room
                v-for="(room, i) in CURRENT_CORPS.rooms"
                :key="i"
                :room="room"
                :corps="CURRENT_CORPS" />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import VRoom from '@/components/v-room';

export default {
    name: 'v-rooms-list',
    components: {
        VRoom
    },
    computed: {
        ...mapGetters([
            'LOCALE',
            'CURRENT_CORPS'
        ])
    },
    data: () => ({}),
    methods: {
        ...mapMutations([
            'SET_IS_ROOMS_LIST',
            'SET_IS_POPUP'
        ]),
    }
};
</script>

<style lang="scss">
.v-rooms-list
{
    .corps__rooms-nav {display: flex;}
}
</style>
