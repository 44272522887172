<template>
	<div class="search">
		<h1>{{$filters.localize('variantesTitle')}}:</h1>

        <div class="search__corpses-filter">
            <div
                v-for="building in BUILDINGS"
                :key="building.hostelid"
                :class="{active: building.hostelid === currentCorps}"
                @click="filterCorpuses(building.hostelid)">
                {{building.name[LOCALE]}}
            </div>
        </div>

        <div class="search__corpses-list">
            <v-corps
                v-for="(corps, i) in buldings"
                :key="i"
                :corps="corps"/>
        </div>

        <v-popup
            v-if="IS_ROOMS_LIST"
            :timeOut="600"
            class="search__popup-room-photos search__popup-room-list top"
            :popupName="'isRoomsList'"
            :cbClose="CLEAR_IS_ROOMS_LIST"
        >
            <v-rooms-list />
        </v-popup>

        <transition name="fade">
            <v-popup-loading
                v-if="IS_LOADING"
                :text="$filters.localize('weBook')" />
        </transition>
	</div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import VCorps from '@/components/v-corps';
import VRoomsList from '@/components/v-rooms-list';

export default {
	name: 'v-search',
    components: {
        VCorps,
        VRoomsList
    },
	computed: {
        ...mapGetters([
            'BUILDINGS',
            'LOCALE',
            'IS_ROOMS_LIST',
            'IS_LOADING'
        ]),
        buldings()
        {
            return this.currentCorps === null
                ? this.BUILDINGS
                : this.BUILDINGS.filter(b => b.hostelid === this.currentCorps)
        }
    },
    data() {
        return {
            currentCorps: null,
        }
    },
	methods: {
		...mapActions([
			'GET_BUILDINGS',
            'START_TIMESTAMP_CHEKER'
		]),
        ...mapMutations([
            'SET_CURRENT_STEP',
            'SET_TIMESTAMP',
            'CLEAR_ALL_FOR_SESSION',
            'CLEAR_IS_ROOMS_LIST',
        ]),
        filterCorpuses(id)
        {
            this.currentCorps == null || this.currentCorps !== id
                ? this.currentCorps = id
                : this.currentCorps = null
        }
	},
	async mounted()
    {
        this.CLEAR_ALL_FOR_SESSION()
        this.SET_TIMESTAMP(Date.now())
        this.START_TIMESTAMP_CHEKER()
    },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all var(--timeOut, .3s) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}


.search
{
	color: #0B132B;
	padding: 48px 14px 60px;

    @include tablet
    {
        padding-top: 28px;
        padding-bottom: 70px;
    }

    @include desktop
    {
        padding: 26px 0 25px 23px;
        position: relative;
        z-index: 100;
    }

    h1
    {
        @include font-set(18, 1.2, 700);

        @include tablet
        {
            margin-bottom: 12px;
        }

        @include desktop
        {
            font-size: 24px;
            margin-bottom: 22px;
        }
    }

    &__corpses-filter
    {
        display: flex;
        flex-wrap: wrap;

        div
        {
            @include font-set(13, 30px, 400);
            text-transform: lowercase;
            padding: 0 13px;
            min-width: 76px;
            margin-right: 19px;
            white-space: nowrap;
            border: 1.5px solid transparent;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 18px;
            border-color: #FFBA00;

            &.active
            {
                // border-color: #FFBA00;
                background-color: #FFBA00;
            }

            @include tablet
            {
                &:hover {background-color: #FFBA00;}
            }

            @include desktop {margin-bottom: 10px;}
        }
    }

    &__corpses-list
    {
        .corps
        {
            &:not(:last-child) {margin-bottom: 20px;}
        }
    }

    &__popup-room-photos
    {
        .popup__content
        {
            margin: 0;
            width: 100vw; height: 100vh;
        }
        .popup__close
        {
            border-radius: 50%;
            right: unset;
            left: 0;
            top: 15px;

            @include desktop {top: 1px;}
        }
        .popup__close-btn
        {
            &::before,
            &::after
            {
                width: 12px;
                height: 3px;
            }
            &::before
            {
                transform: translate(-50%, calc(-50% + 3.5px)) rotate(40deg);
            }
            &::after
            {
                transform: translate(-50%, calc(-50% - 3.5px)) rotate(-40deg);
            }
        }
    }

    &__popup-room-list
    {
        .popup__close
        {
            @include desktop {top: 14px;}
        }
    }
}
</style>
