<template>
	<div class="booking container">
		<aside class="booking__aside">
			<ul class="booking__aside-steps">
				<li
					v-for="step in STEPS"
					:key="step.id"
					@click="changeStep(step.id)"
					:class="{
						active: step.id == CURRENT_STEP,
						pointer: step.id < CURRENT_STEP,
					}"
				>
					<div class="booking__aside-steps-wrap">
						<div
							class="booking__aside-steps-num"
							:class="{
								violet: CURRENT_STEP >= 2 && step.id == 1,
								green: CURRENT_STEP >= 3 && step.id == 2,
								orange: CURRENT_STEP >= 3 && step.id == 3,
							}"
						>
							{{ step.id }}
						</div>
						<div
							class="booking__aside-steps-text"
							v-html="$filters.localize(step.text)"
						></div>
					</div>
					<div class="booking__aside-steps-spacer"></div>
				</li>
			</ul>
		</aside>
		<div class="booking__content">
			<div class="booking__content-wrapper">
				<div
					class="booking__content-marker _booking__content-marker"
					ref="marker"
				>
					<img :src="markerIcons[CURRENT_STEP - 1]" alt="icon" />
					<div
						class="booking__content-marker-text"
						v-html="$filters.localize(STEPS[CURRENT_STEP - 1].text)"
					></div>
				</div>

				<component :is="step">
					<router-view />
				</component>
			</div>

			<!-- <div
				class="booking__black-screen"
				v-if="ROOM_PHOTO_SLIDER_POPUP"
				@click.self="onClosePopup('RoomPhotoSliderPopup', false)"
			></div> -->

            <transition name="fade">
                <div
                    class="booking__black-screen"
                    v-if="ROOM_PHOTO_SLIDER_POPUP"
                    @click.self="onClosePopup('RoomPhotoSliderPopup', false)"
                ></div>
            </transition>

			<v-photo-popup
				:openUp="ROOM_PHOTO_SLIDER_POPUP"
				:popupName="'RoomPhotoSliderPopup'"
				@onClose="onClosePopup"
			>
				<v-room-photos
					:popupName="'RoomPhotoSliderPopup'"
					@onClose="onClosePopup"
				/>
			</v-photo-popup>
		</div>
		<button class="booking__help" @click="openHelp">
			<img v-svg-inline src="@/assets/svg/faq.svg" alt="faq" />
		</button>

		<!-- v-if="ROOM_PHOTO_SLIDER_POPUP" -->
		<!-- :openUp="ROOM_PHOTO_SLIDER_POPUP" -->
		<!-- <v-popup
            v-if="ROOM_PHOTO_SLIDER_POPUP"
            :timeOut="800"
            class="booking__popup-room-photos top"
            :bgColor="'#0B132BB0'"
            :popupName="'RoomPhotoSliderPopup'">
            <v-room-photos
                @close="SET_IS_POPUP({popup: 'RoomPhotoSliderPopup', value: false})"
            />
        </v-popup> -->

		<v-popup
			v-if="IS_SESSION_DIED"
			noClose
			:timeOut="300"
			class="booking__popup-session-died top"
			:popupName="'isSessionDiedPopup'"
		>
			<v-session-died />
		</v-popup>

		<v-popup
			v-if="IS_ROOMS_NOT_FOUND"
			:timeOut="300"
			class="booking__popup-rooms-not-found top"
			:popupName="'isRoomsNotFound'"
			:cbClose="SET_IS_ROOMS_NOT_FOUND"
		>
			<v-rooms-not-found />
		</v-popup>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import vPrice from '@/components/v-price';
import vSearch from '@/components/v-search';
import vSuccess from '@/components/v-success';
import vRoomPhotos from '@/components/v-room-photos';
import vSessionDied from '@/components/v-session-died';
import vRoomsNotFound from '@/components/v-rooms-not-found';
import vPhotoPopup from '@/components/v-photo-popup.vue';

export default {
	name: 'v-booking',
	components: {
		vPrice,
		vSearch,
		vSuccess,
		vRoomPhotos,
		vSessionDied,
		vRoomsNotFound,
		vPhotoPopup,
	},
	computed: {
		...mapGetters([
			'LOCALE',
			'STEPS',
			'CURRENT_STEP',
			'ROOM_PHOTO_SLIDER_POPUP',
			'IS_SESSION_DIED',
			'IS_ROOMS_NOT_FOUND',

			'CURRENT_ROOM',
			'CURRENT_CORPS',
		]),
		step() {
			return (
				this.STEPS.find((s) => s.id === this.CURRENT_STEP).step ||
				'v-price'
			);
		},
	},
	watch: {
		CURRENT_STEP() {
			setTimeout(() => this.setMarkerOffset(), 0);
		},
		LOCALE() {
			document.title = this.$filters.localize('booking');
		},
	},
	data() {
		return {
			markerIcons: [
				require('@/assets/img/marker_1.png'),
				require('@/assets/img/marker_2.png'),
				require('@/assets/img/marker_3.png'),
			],
		};
	},
	methods: {
		...mapMutations([
			'SET_CURRENT_STEP',
			'SET_IS_POPUP',
			'SET_IS_ROOMS_NOT_FOUND',
		]),
		onClosePopup(popup, value) {
			this.SET_IS_POPUP({ popup, value });
		},
		changeStep(id) {
			id < this.CURRENT_STEP && this.SET_CURRENT_STEP(id);
		},
		setMarkerOffset() {
			const clientWidth = document.body.clientWidth;
			const markerWidth = this.$refs.marker.offsetWidth;

			if (clientWidth < 1024) {
				const currentOffset = document.querySelector(
					'.booking__aside-steps li.active'
				).offsetLeft;
				this.$refs.marker.style.left =
					currentOffset - markerWidth / 4 + 'px';
				this.$refs.marker.style.top = 0;
			} else {
				const currentOffset = document.querySelector(
					'.booking__aside-steps li.active'
				).offsetTop;
				this.$refs.marker.style.top = currentOffset - 8 + 'px';
				this.$refs.marker.style.left = 0;
			}
		},
		openHelp() {
			this.SET_IS_POPUP({ popup: 'HelpPopup', value: true });
		},
	},
	beforeCreate() {
		setTimeout(
			() => (document.title = this.$filters.localize('booking')),
			0
		);
	},
	mounted() {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		setTimeout(() => this.setMarkerOffset(), 0);
		setTimeout(
			() => (this.$refs.marker.style.transition = 'all .3s ease'),
			300
		);
		window.addEventListener('resize', () => this.setMarkerOffset());
	},
	beforeUnmount() {
		this.$watch = {};
		this.SET_CURRENT_STEP(1);
		window.removeEventListener('resize', () => this.setMarkerOffset());
	},
};
</script>

<style lang="scss">
.booking {
	flex: 1 0;
	display: flex;
	flex-direction: column;

	&.container {
		width: 100%;
		padding: 0;
		padding-top: 70px;
		margin: 0;
	}

	@include tablet {
		&.container {
			padding: 0;
			padding-top: 80px;
		}
	}

	@include desktop {
		flex-direction: row;
		&.container {
			padding: 0;
			padding-top: 0;
			margin: 0 auto;
		}
	}

	&__aside {
		margin-bottom: 61px;

		@include desktop {
			flex: 1 0;
			min-height: 100%;
			max-width: 273px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			@include desktop {
				justify-content: flex-start;
			}
		}
	}

	&__aside-steps {
		padding: 0 31px;
		display: flex;
		justify-content: space-between;

		li {
			position: relative;
			overflow: hidden;
			margin-right: 10px;
			display: flex;
			align-items: center;

			&:not(:last-child) {
				flex: 1 0;
			}

			&:last-child {
				margin-right: 0;
				.booking__aside-steps-spacer {
					display: none;
				}
			}

			&.pointer {
				cursor: pointer;
			}

			&.active {
				&:nth-child(1) .booking__aside-steps-num {
					border-color: #5f4fc3;
				}
				&:nth-child(2) .booking__aside-steps-num {
					border-color: #00a94f;
				}
			}
		}

		@include tablet {
			padding: 0 121px 0 132px;
		}

		@include desktop {
			padding: 0;
			flex-direction: column;
			height: 290px;
			margin-top: 202px;

			li {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	&__aside-steps-wrap {
		display: flex;
		align-items: center;
	}

	&__aside-steps-spacer {
		flex: 1 0;
		position: relative;
		margin-left: 10px;

		&::before {
			content: '';
			width: 100%;
			height: 5px;
			background-image: radial-gradient(
				circle,
				rgba(255, 255, 255, 0.5) 2px,
				transparent 3px
			);
			background-position: center;
			background-size: 9px 5px;
			background-repeat: space no-repeat;

			position: absolute;
			left: 0;
		}

		@include desktop {
			margin: 10px 0;
			margin-left: 23px;
			&::before {
				width: 5px;
				height: 100%;
				background-size: 5px 9px;
				background-repeat: no-repeat space;
			}
		}
	}

	&__aside-steps-num {
		width: 50px;
		min-width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid rgba(255, 255, 255, 0.4);
		border-radius: 50%;

		&.violet {
			background: #5f4fc3;
			border: none;
		}
		&.green {
			background: #00a94f;
			border: none;
		}
		&.orange {
			background: #f26522;
			border: none;
		}

		@include tablet {
		}

		@include desktop {
		}
	}

	&__aside-steps-text {
		display: none;

		@include tablet {
			display: block;
			@include font-set(12, 0.9, 500);
			margin-left: 8px;
			color: #9da1aa;
			width: fit-content;
		}

		@include desktop {
			font-size: 16px;
		}
	}

	&__content {
		min-height: 100%;
		background-color: #eeeff4;
		flex: 1 0;
		position: relative;

		@include desktop {
			padding-right: 124px;
		}
	}

	&__content-wrapper {
		min-height: 100%;
		position: relative;
		z-index: 20;
	}

	&__content-marker {
		width: 97px;
		height: 67px;
		background: url('~@/assets/svg/marker.svg') center / cover no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(0, -73%);
		z-index: -1;

		@media (prefers-color-scheme: dark) {
			// background-image: url('~@/assets/svg/marker_dark.svg');
		}

		@include desktop {
			left: 0;
			transform: translate(-67%, 0) rotate(-90deg);
		}

		img {
			position: absolute;
			left: 50%;
			right: 50%;
			transform: translate(-50%, 25%);

			@include desktop {
				transform: translate(-50%, 30%) rotate(90deg);
			}
		}
	}

	&__content-marker-text {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		color: #9da1aa;
		@include font-set(12, 0.9, 500);
		text-align: center;

		@include tablet {
			display: none;
		}
	}

	&__help {
		position: fixed;
		bottom: 54px;
		right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 3px;
		width: 56px;
		height: 56px;
		background-color: #0056a6;
		border-radius: 50%;
		z-index: 100;

		svg path {
			fill: #0b1a46;
		}

		@include tablet {
			bottom: 145px;
		}
		@include desktop {
			display: none;
		}
	}

	&__popup-room-photos {
		.popup__content {
			margin: 0;
			width: 100vw;
			height: 100vh;
		}
		.popup__close {
			border-radius: 50%;
			right: unset;
			left: 0;
			top: 12px;

			@include desktop {
				top: 1px;
			}
		}
		.popup__close-btn {
			&::before,
			&::after {
				width: 12px;
				height: 3px;
			}
			&::before {
				transform: translate(-50%, calc(-50% + 3.5px)) rotate(40deg);
			}
			&::after {
				transform: translate(-50%, calc(-50% - 3.5px)) rotate(-40deg);
			}
		}
	}

	&__popup-session-died {
		background-color: rgba(255, 255, 255, 0.95);

		.popup__content {
			margin-top: 140px;
		}
		.popup__close {
			display: none;
		}
	}

	&__popup-rooms-not-found {
		.popup__content {
			width: 100%;
		}
		.popup__close {
			right: 28px;
		}
	}

	&__black-screen {
		position: fixed;
		top: 0;
		left: 0;
		background-color: #00000080;
		width: 100vw;
		height: 100vh;
		z-index: 99;
		display: none;

		@include desktop {
			display: block;
		}
	}
}

.fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
