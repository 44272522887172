<template>
	<div class="success">
		<div v-if="isAccept">
			<h1 v-if="STATUS === 'success'">{{SUCCESS_DESCRIPTION}}</h1>
			<h1 v-else>{{this.$filters.localize(status())}}</h1>
			<!-- <h1 v-html="this.$filters.localize(status())"></h1> -->

			<button v-if="STATUS !== 'success'" class="success__btn" @click="startOver">
				{{this.$filters.localize('startOver')}}
			</button>
		</div>
		<div v-if="!isAccept">
			<h1>{{this.$filters.localize('successGoodСhoice')}}</h1>
			<h2>{{this.$filters.localize('successСontract')}}</h2>
			<h2>{{this.$filters.localize('successAttention')}}</h2>
			<div class="success__actions">
				<button @click="toBook" class="success__btn double orange">
					{{this.$filters.localize('isAccept')}}
				</button>
				<button @click="goBack" class="success__btn double green">
					{{this.$filters.localize('returnToSelection')}}
				</button>
			</div>
		</div>

		<transition name="fade">
            <v-popup-loading
                v-if="IS_LOADING"
                :text="$filters.localize('weBook')" />
        </transition>
	</div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
	name: 'v-success',
	computed: {
		...mapGetters([
			'SUCCESS_DESCRIPTION',
			'IS_LOADING',
			'CURRENT_STEP'
		]),
	},
	data: () => ({
		STATUS: 'success',
		isAccept: false
	}),
	methods: {
		...mapMutations([
			'SET_CURRENT_STEP',
			'SET_LOADING',
			'SET_SELECTED_ROOM',
			'SET_CURRENT_STEP'
		]),
		...mapActions([
            'TO_BOOK'
        ]),
		status()
		{
			return this.STATUS === 'success' ? 'success' : 'notSuccess'
		},
		startOver()
		{
			this.SET_CURRENT_STEP(1)
		},
		goBack() {
            this.SET_CURRENT_STEP(this.CURRENT_STEP - 1)

		},
		 async toBook()
        {
            this.SET_LOADING(true)
            const res = await this.TO_BOOK(this.room)
            this.SET_LOADING(false)
			this.isAccept = true
            this.STATUS = res.status
        },
	},
	mounted()
	{
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all var(--timeOut, .3s) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}

.success
{
	color: #0B132B;
	padding: 87px 15px;
	text-align: center;

	@include tablet
	{
		padding-top: 98px;
	}

	@include desktop
	{
		padding: 206px 100px;
		text-align-last: left;
	}

	h1
	{
		@include font-set(22, 1, 700);
		margin-bottom: 32px;
		text-align: center;

		@include tablet
		{
			margin-bottom: 62px;
		}

		@include desktop
		{
			text-align: left;
			margin-bottom: 41px;
		}
	}

	h2
	{
		@include font-set(18, 1.2, 700);
		margin-bottom: 32px;
		text-align: center;

		@include tablet
		{
			margin-bottom: 62px;
		}

		@include desktop
		{
			text-align: left;
			margin-bottom: 41px;
		}
	}

	&__actions
	{
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
		}
	}

	&__btn
	{
		display: inline-flex;
		padding: 0 60px;
		background-color: #FFBA00;
		border-radius: 30px;
		@include font-set(14, 50px, 700);
		color: #fff;
		text-transform: uppercase;
		justify-content: center;

		&.orange {background-color: #F26522;}
		&.green {background-color: #00A94F;}
		&.double {
			flex: 1;
			padding: 0 10px;
		}

		&:first-child {margin-bottom: 20px;}

		@include tablet {
			&:first-child {
				margin-right: 20px;
				margin-bottom: 0;
			}
		}

		@include desktop
		{
			margin: 0;
		}
	}
}
</style>