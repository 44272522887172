<template>
    <div class="back-btn">
		<div class="back-btn__btn"></div>
	</div>
</template>

<script>
export default {
	name: "back-btn"
}
</script>

<style lang="scss">
.back-btn {
	width: 48px;
	height: 48px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover .back-btn__btn,
	&:active .back-btn__btn
	{
		&::before,
		&::after {background-color: #00A94F;}
	}

	&__btn {
		width: 24px;
        height: 24px;
        border-radius: 50%;
        position: relative;

        &::before,
        &::after
        {
            content: '';
            width: 12px;
            height: 3px;
            position: absolute;
            background-color: #CDCFD8;
            left: 50%;
            top: 50%;
        }
        &::before
        {
            transform: translate(-50%, calc(-50% + 3.5px)) rotate(40deg);
        }
        &::after
        {
            transform: translate(-50%, calc(-50% - 3.5px)) rotate(-40deg);
        }
	}
}
</style>