<template>
    <div class="price">
        <h1>{{this.$filters.localize('selectPrices')}}</h1>
        <v-popup-loading
            v-if="isPricesLoading"
            :text="$filters.localize('loading')" />
        <div v-else>
            <div class="price__wrapper">
                <div class="price__range-slider-wrapper">
                    <div class="price__range-slider" id="price__range-slider" ref="rangeSlider"></div>
                    <div class="price__range">
                        <div class="price__min-price">{{PRICE_RANGE[0]}}</div>
                        <div class="price__max-price">{{$filters.currency(PRICE_RANGE[1])}}</div>
                    </div>
                </div>
                <div class="price__range-desc">{{$filters.localize('manyPerMonth')}}</div>
            </div>
            <button
                class="price__show-variantes"
                @click="showVariantes">
                {{ $filters.localize('showVariantes') }}
                <div class="price__show-variantes-img"></div>
            </button>
        </div>
        <!-- <div v-if="MAX_PRICE === 0 || loading">{{$filters.localize('loading')}}....</div> -->
        <v-popup-loading
            v-if="isCorpusesLoading"
            :text="$filters.localize('search')" />
    </div>
</template>

<script>
    import noUiSlider from 'nouislider';
    import 'nouislider/distribute/nouislider.css';

    import {mapActions, mapMutations, mapGetters} from 'vuex';

    export default {
        name: 'v-price',
        computed: {
            ...mapGetters([
                'MIN_PRICE',
                'MAX_PRICE',
                'PRICE_RANGE',
                'CURRENT_STEP'
            ]),
        },
        data() {
            return {
                slider: null,
                isPricesLoading: false,
                isCorpusesLoading: false
            };
        },
        methods: {
            ...mapMutations([
                'SET_PRICE',
                'SET_CURRENT_STEP'
            ]),
            ...mapActions([
                'GET_PRICES',
                'GET_BUILDINGS'
            ]),
            async showVariantes()
            {
                this.isCorpusesLoading = true
                const resp = await this.GET_BUILDINGS()
                this.isCorpusesLoading = false

                if (resp) this.SET_CURRENT_STEP(this.CURRENT_STEP + 1)
            },
            changePrices(prices)
            {
                this.SET_PRICE({
                    key: 'min_price',
                    price: prices[0]
                })
                this.SET_PRICE({
                    key: 'max_price',
                    price: prices[1]
                })
            },
            initRangeSlider(min, max)
            {
                const slider = this.$refs.rangeSlider
                this.slider = noUiSlider.create(slider, {
                    format: {
                        to: (val) => Math.round(val),
                        from: (val) => Math.round(val)
                    },
                    start: [max * 1, max * 1],
                    connect: true,
                    tooltips: [true, true],
                    range: {
                        'min': this.MIN_PRICE,
                        '10%': [1000, 500],
                        'max': this.MAX_PRICE
                    }
                })
                .on('update', (prices, handle, unencoded, tap, positions) => {
                    this.changePrices(prices)
                    this.wathTooltips(handle, positions)
                })
            },
            wathTooltips(handle, positions)
            {
                if (window.innerWidth > 768) return

                const tooltips = document.querySelectorAll('.noUi-tooltip')
                const maxPositions = [10, 88.75]

                if (handle === 0 && positions[handle] < maxPositions[handle]) {tooltips[handle].classList.add('left')}
                else if (handle === 1 && positions[handle] > maxPositions[handle]) {tooltips[handle].classList.add('right')}
                else
                {
                    tooltips[handle].classList.remove('left')
                    tooltips[handle].classList.remove('right')
                }
            }
        },
        async mounted()
        {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            this.SET_PRICE({key: 'max_price',price: 0})
            this.isCorpusesLoading = false
            this.isPricesLoading = true
            await this.GET_PRICES()
            this.isPricesLoading = false
            setTimeout(() => {this.initRangeSlider(this.MIN_PRICE, this.MAX_PRICE)}, 0)
        }
    }
</script>

<style lang="scss">
.price
{
    color: #0B132B;
    // padding-top: 96px;
    padding: 48px 16px;

    @include tablet
    {
        padding: 159px 52px;
    }

    @include desktop
    {
        padding: 84px 86px 84px 122px;
    }

    h1
    {
        @include font-set(18, 1, 700);
        margin-bottom: 65px;

        @include desktop
        {
            font-size: 24px;
            margin-bottom: 100px;
        }
    }

    &__wrapper
    {
        display: flex;
    }

    &__range-slider-wrapper
    {
        flex: 1 0;
    }

    &__range-slider
    {
        flex: 1 0;
    }

    &__range-desc
    {
        display: none;

        @include tablet
        {
            display: block;
            white-space: nowrap;
            @include font-set(14, 1.2, 500);
            color: #8D93A1;
            margin-left: 19px;
        }
    }

    &__range
    {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;

        & > div
        {
            @include font-set(14, 1.2, 500);
            color: #8D93A2;
        }
    }

    &__show-variantes
    {
        padding-top: 2px;
        @include font-set(12, 48px, 700, .1em);
        color: #fff;
        width: 243px;
        background-color: #0079C1;
        border-radius: 30px;
        text-align: center;
        text-transform: uppercase;
        padding-right: 57px;
        position: relative;
        margin-top: 31px;
        padding-left: 8px;
        white-space: nowrap;

        &:hover
        {
            background-color: #0056A6;

            .price__show-variantes-img {background-color: #0079C1;}
        }

        @include tablet
        {
            padding-left: 0;
            width: 286px;
            font-size: 14px;
            line-height: 60px;
            margin-top: 61px;
        }

        @include desktop
        {
            margin-top: 102px;
        }
    }

    &__show-variantes-img
    {
        width: 46px;
        height: 46px;
        background-color: #0056A6;
        border-radius: 50%;
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);

        &::before
        {
            content: '';
            width: 11px;
            height: 16px;
            background: url('../assets/svg/arrow-empty-r.svg') center / auto no-repeat;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }

        @include tablet
        {
            width: 56px;
            height: 56px;
        }
    }

    .noUi-target
    {
        border-radius: 50px;
        height: 15px;
    }

    .noUi-connect {background-color: #00A94F;}

    .noUi-handle
    {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-color: #fff;
        top: -8px;

        &::before {display: none;}
        &::after
        {
            width: 15px;
            height: 15px;
            background-color: #0079C1;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .noUi-tooltip
    {
        color: #fff;
        background: #0056A6;
        border-radius: 3px;
        font-size: 18px;
        line-height: 23px;
        font-weight: bold;
        min-width: 63px;
        padding: 0 4px;
        border: none;
        bottom: unset;
        top: -37px;
        left: -38px;
        transform: unset;
        height: 20px;

        &:before
        {
            content: '';
            border: 10px solid transparent;
            border-top: 12px solid #0056A6;
            position: absolute;
            bottom: 0;
            right: 2px;
            transform: translateY(100%);
        }

        &.left
        {
            left: 0;
            &:before {right: 41px;}
        }
    }

    .noUi-handle-upper
    {
        .noUi-tooltip
        {
            left: 2px;
            &:before {left: 2px; right: unset;}

            &.right
            {
                left: -38px;
                &:before {left: 41px;}
            }
        }
    }
}
</style>
