<template>
	<div class="photo-popup" :class="{ open: isOpen }">
		<div
			class="photo-popup__content"
		>
			<div class="photo-popup__photo-wrapper" @click.self="$emit('onClose', popupName, false)">
				<div class="photo-popup__photo-content">
					 <transition name="fade-slide">
						<slot v-if="openUp"></slot>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import vCloseBtn from './v-close-btn';
import vBackBtn from './v-back-btn';

export default {
	name: 'photo-popup',
	components: {
		vCloseBtn,
		vBackBtn,
	},
	props: {
		openUp: {
			type: Boolean,
			default: false,
		},
		popupName: {
			type: String,
			default: '',
		},
	},
	watch: {
		openUp(v) {
			v ? this.open() : this.close();
		},
	},
	computed: {
		...mapGetters(['LOCALE']),
	},
	data() {
		return {
			body: document.querySelector('body'),
			lockPadding: document.querySelector('.lock-padding'), // for fixed elements
			unlock: true,
			$popupActive: null,
			isOpen: false,
		};
	},
	methods: {
		open() {
			if (this.unlock) {
				this.$popupActive = document.querySelector('.popup.open');
				this.$popupActive
					? this.$popupActive.classList.remove('open')
					: this.bodyLock();
				this.isOpen = true;
			}
		},
		close(doUnlock = true, force = false) {
			if (!force && this.noClose) return;

			if (this.unlock) {
				this.isOpen = false;
				if (doUnlock) this.bodyUnlock();
				setTimeout(() => {
					this.$emit('onClose', this.popupName, false);
				}, this.timeOut);

				if (this.$popupActive) this.$popupActive.classList.add('open');
			}
		},
		bodyLock() {
			const lockPaddingValue =
				window.innerWidth - this.body.offsetWidth + 'px';

			this.body.style.paddingRight = lockPaddingValue;
			this.body.classList.add('lock');

			if (this.lockPadding && this.lockPadding.length) {
				this.lockPadding.forEach(
					(el) => (el.style.paddingRight = lockPaddingValue)
				);
			}

			this.unlock = false;
			setTimeout(() => {
				this.unlock = true;
			}, this.timeOut);
		},
		bodyUnlock() {
			this.unlock = false;
			setTimeout(() => {
				if (this.lockPadding && this.lockPadding.length)
					this.lockPadding.forEach(
						(el) => (el.style.paddingRight = '0px')
					);
				this.body.style.paddingRight = '0px';
				this.body.classList.remove('lock');

				this.unlock = true;
			}, this.timeOut);
		},
		closeOnEsc() {
			document.addEventListener('keydown', (e) => {
				e.which === 27 && this.close();
			});
		},
	},
};
</script>

<style lang="scss">
.photo-popup {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #00000080;
	width: 100%;
	height: 100%;
	// transition: all ease 0.3s;
	transition: transform .3s, opacity .3s;
	transition-timing-function: linear;
	// transition: opacity ease 0.3s;
	transform: translateX(100%);
	visibility: hidden;

    &.open
    {
        transform: translateX(0);
		visibility: visible;
    }

    @include tablet {
        transform: translateX(0);
        opacity: 0;
		visibility: hidden;
		position: absolute;

        &.open
        {
            opacity: 1;
			visibility: visible;
        }
    }

	&.open {
		opacity: 1;
		visibility: visible;
	}

	&__content {
		position: sticky;
		height: 100vh;
		top: 0;
		display: flex;
		flex-direction: column;
	}

	&__photo-wrapper {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__photo-content {
		width: 100%;
	}
}

.fade-slide-enter-active, .fade-slide-leave-active {
    transition: all .3s ease;
}
.fade-slide-enter, .fade-slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;

	@include tablet {
		transform: translateX(100%);
	}
}
</style>
