<template>
    <div class="corps" ref="corps">
        <div class="corps__main">
            <div class="corps__img">
                <!-- <img src="@/assets/img/corps.jpg" alt=""> -->
                <img :src="URL + corps.image" alt="">
            </div>

            <div class="corps__wrapper">
                <div class="corps__data">
                    <div class="corps__title">
                        <div>
                            <span class="corps__title-name">{{corps.name[LOCALE]}}</span>
                            /
                            <span class="corps__title-type">{{corps.type[LOCALE]}}</span>
                        </div>
                        <div class="corps__title-addr" :class="{active: isShowRooms}">
                            <span>{{corps.address[LOCALE]}}</span>
                        </div>
                    </div>
                    <div class="corps__desc" :class="{active: isShowRooms}">
                        {{corps.description[LOCALE]}}
                    </div>
                    <div
                        class="corps__conditions"
                        >
                        <div
                            class="corps__condition-item _condition"
                            v-for="(condition, i) in corps.conditions"
                            :key="i">
                            <div class="corps__conditions-img">
                                <img :src="URL + condition.image" alt="condition">
                            </div>
                            <div class="corps__conditions-text">{{condition.text[LOCALE]}}</div>
                        </div>
                    </div>
                    <button
                        class="corps__more-about-hull"
                        @click="showConditions"
                        v-if="!isConditions">
                        {{$filters.localize('moreAboutHull')}}
                    </button>
                </div>

                <div
                    class="corps__footer corps__data-footer"
                    @click="toggleRooms"
                    :class="{hide: isShowRooms}"
                >
                    <div class="corps__variantes">
                        {{corps.rooms.length}}
                        {{declOfNum(corps.rooms.length, $filters.localize('variantes'))}}
                        размещения
                    </div>
                    <div class="corps__show-rooms">
                        {{$filters.localize('seeVariantes')}} <span>({{corps.rooms.length}})</span>
                        <div class="corps__btn corps__data-footer-btn"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="corps__rooms _rooms" ref="rooms">
            <div class="corps__rooms-nav">
                <button
                    class="corps__rooms-nav-back"
                    @click="toggleRooms">
                </button>
                <div class="corps__rooms-corps-addr">
                    <span class="corps__title-name">{{corps.name[LOCALE]}}</span>
                    /
                    <span class="corps__title-type">{{corps.type[LOCALE]}}</span>
                </div>
            </div>
            <div class="corps__rooms-wrapper">
                <v-room
                    v-for="(room, i) in corps.rooms"
                    :key="i"
                    :room="room"
                    @toggleRooms="toggleRooms"
                    :corps="corps" />
            </div>

            <div class="corps__footer corps__rooms-footer" @click="toggleRooms">
                <div class="corps__hide-rooms">
                    {{$filters.localize('rollUp')}}
                    <div class="corps__btn corps__minus-btn"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import VRoom from '@/components/v-room';
import {slideToggle, slideDown} from '@/services/domNodeSlide'

export default {
    name: 'v-corps',
    components: {
        VRoom
    },
    props: {
        corps: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapGetters([
            'LOCALE',
            'IS_ROOMS_LIST'
        ]),
    },
    watch: {
        IS_ROOMS_LIST(v)
        {
            if (!v)
            {
                this.isShowRooms = false
            }
        }
    },
    data() {
        return {
            URL: process.env.VUE_APP_URL,
            conditionHeight: 0,
            isConditions: false,
            isShowRooms: false,
        }
    },
    methods: {
        ...mapMutations([
            'SET_CURRENT_CORPS',
            'SET_IS_ROOMS_LIST',
            'CLEAR_IS_ROOMS_LIST'
        ]),
        declOfNum(n, titles) {
            return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
        },
        showConditions()
        {
            document.querySelectorAll('._condition').forEach((el, i) => {
                if (i > 3) slideDown(el, 300, 'flex')
            })
            this.isConditions = !this.isConditions
        },
        toggleRooms()
        {
            if (this.isShowRooms ?? !this.IS_ROOMS_LIST)
            {
                this.isShowRooms = !this.isShowRooms
                slideToggle(this.$refs.rooms, 1000)

                // window.parent.scrollTo({
                //     top: this.$refs.corps.offsetTop - 10,
                //     behavior: 'smooth'
                // })
                return
            }

            this.isShowRooms = !this.isShowRooms
            // setTimeout(() => {
            //     window.parent.scrollTo({
            //         top: this.$refs.corps.offsetTop + this.$refs.corps.offsetHeight,
            //         behavior: 'smooth'
            //     })
            // }, 0)
            // console.log('this.$refs.roomsWrapper', this.$refs.corps);
            window.innerWidth > 767
                ? slideToggle(this.$refs.rooms, 1000)
                : this.showRoomsPopup()
        },
        showRoomsPopup()
        {
            this.SET_CURRENT_CORPS(this.corps)
            this.SET_IS_ROOMS_LIST(true)
        }
    },
}
</script>

<style lang="scss">
.corps
{
    border-bottom-right-radius: 10px;
    overflow: hidden;
    background: #fff;

    &__btn
    {
        width: 30px; height: 30px;
        border-radius: 50%;
        background-color: #0056A6;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 18px;

        &::before,
        &::after
        {
            content: '';
            display: block;
            width: 10px;
            height: 2px;
            background-color: #fff;
            position: absolute;
        }
        &::after
        {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    &__main
    {
        @include tablet
        {
            display: flex;
        }
    }

    &__img
    {
        // max-width: 289px;
        max-height: 400px;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 10px;

        img
        {
            width: 100%; height: 100%;
            object-fit: cover;
        }

        @include tablet
        {
            margin: 0;
            flex: 1 0;
            min-height: 319px;
            max-width: 270px;
        }

        @include desktop
        {
            max-width: 230px;
        }

        @include widescreen
        {
            max-width: 288px;
        }
    }

    &__wrapper
    {
        flex: 1 0;

        @include tablet
        {
            display: flex;
            flex-direction: column;
        }
    }

    &__data
    {
        padding: 0 15px;

        @include tablet
        {
            flex: 1 0;
            padding: 14px 9px 0 20px;
        }

        @include desktop
        {
            padding: 24px 20px 0;
        }
    }

    &__title
    {
        margin-bottom: 7px;
        line-height: 1rem;

        @include desktop
        {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
        }
    }

    &__title-name
    {
        @include font-set(16, 1.2rem, 700);

        @include tablet {line-height: 1rem;}

        @include desktop {font-size: 18px;}
    }

    &__title-type
    {
        @include font-set(14, 1.2rem, 400);

        @include tablet
        {
            font-size: 16px;
            line-height: 1em;
        }
    }

    &__title-addr
    {
        @include font-set(14, 1.2rem, 400);

        &.active {-webkit-line-clamp: inherit;}

        @include tablet
        {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1em;
            font-size: 16px;
        }

        @include desktop {font-size: 16px;}
    }

    &__desc
    {
        @include font-set(14, 1.1rem, 400);
        color: #828282;
        margin-bottom: 22px;

        @include tablet
        {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;
        }

        @include desktop {margin-bottom: 26px;}
    }

    &__conditions
    {

        @include tablet
        {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include desktop {margin-bottom: 15px;}
    }

    &__condition-item
    {
        flex-basis: 48%;
        transition: all .3s ease;
        margin-bottom: 13px;
        display: flex;
        align-items: center;


        &:nth-child(n+4) {display: none;}

        @include tablet
        {
            margin-bottom: 10px;

            &:nth-child(n+4) {display: flex;}
        }
    }

    &__conditions-img
    {
        min-width: 40px;
    }

    &__conditions-text
    {
        @include font-set(13, 1em, 400);

        @include tablet
        {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include desktop
        {
            font-size: 14px;
        }
    }

    &__more-about-hull
    {
        display: block;
        width: fit-content;
        margin-left: auto;
        color: #0079C1;
        text-decoration-line: underline;
        margin-bottom: 13px;

        @include tablet {display: none;}
    }

    &__footer
    {
        height: 50px;
        display: flex;
        align-items: center;
        background-color: #0079C1;
        color: #fff;
        padding-left: 21px;
        padding-right: 10px;
        cursor: pointer;

        &:hover {.corps__btn {background-color: #00A94F;}}
    }

    &__data-footer
    {
        justify-content: center;
        position: relative;
        transition: all .3s ease;

        // &.hide { display: none; }
        &.hide {
            opacity: 0;
            visibility: hidden;
        }

        @include mobile
        {
            justify-content: space-between;
        }
    }

    &__variantes
    {
        display: none;

        @include mobile
        {
            display: block;
        }
    }

    &__show-rooms
    {
        display: flex;
        align-items: center;
        @include font-set(12, 1, 500);
        text-transform: uppercase;

        span {margin-left: 5px;}

        @include mobile
        {
            span {display: none;}
        }
    }

    &__data-footer-btn
    {
        position: absolute;
        right: 10px;

        @include mobile {position: relative;right: 0;}
    }

    &__rooms
    {
        display: none;

        background-color: #0B132B;

        // &.as-popup
        // {
        //     display: block !important;
        //     position: fixed;
        //     left: 0;
        //     top: 0;
        //     z-index: 1000;
        //     height: 100vh;
        //     overflow-y: scroll;

        //     .corps__rooms-nav {display: flex;}

        //     .corps__rooms-footer {display: none;}
        // }

        @include tablet
        {
            background-color: transparent;
        }
    }

    &__rooms-nav
    {
        display: none;
        align-items: center;
        height: 80px;
        padding: 0 15px;
        margin-bottom: 10px;
    }

    &__rooms-nav-back
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        transform: rotate(180deg);

        &::before
        {
            content: '';
            width: 11px;
            height: 16px;
            background: url('../assets/svg/arrow-empty-r.svg') center / auto no-repeat;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }

    &__rooms-corps-addr
    {
        margin-left: 26px;

        &, span {color: #fff;}
    }

    &__title-name
    {
    }

    &__title-type
    {
    }

    &__rooms-wrapper
    {
        padding: 20px 15px;

        .room
        {
            &:not(:last-child) {margin-bottom: 20px;}

            @include tablet
            {
                &:not(:last-child) {margin-bottom: 10px;}
            }
        }
    }

    &__rooms-footer
    {
        justify-content: flex-end;
    }

    &__hide-rooms
    {
        display: flex;
        align-items: center;
    }

    &__minus-btn
    {
        &::after {display: none;}
    }
}

</style>
