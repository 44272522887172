<template>
    <div class="session-died">
        <img :src="require('@/assets/svg/hourglass.svg')" alt="hourglass">
        <div class="session-died__data">
            <div class="session-died__text">
                {{$filters.localize('sessionIsDiedTitle')}}.
            </div>
            <div class="session-died__text">
                {{$filters.localize('sessionIsDiedText')}}.
            </div>
        </div>
        <button class="session-died__btn" @click="updateData">
            {{$filters.localize('updateData')}}
        </button>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
    name: 'session-died',
    methods: {
        ...mapMutations([
            'CLEAR_ALL_FOR_SESSION',
            'SET_CURRENT_STEP'
        ]),
        updateData()
        {
            this.SET_CURRENT_STEP(1)
            this.CLEAR_ALL_FOR_SESSION()
        }
    }
}
</script>

<style lang="scss">
.session-died
{
    text-align: center;
    color: #0B132B;
    max-width: 310px;

    &__data
    {
        margin: 32px 0 24px;
    }

    &__text
    {
        @include font-set(14, 1.2em, 700);
    }

    &__btn
    {
        max-width: 259px;
		background-color: #FFBA00;
		border-radius: 30px;
		@include font-set(14, 50px, 700);
		text-align: center;
		color: #fff;
		text-transform: uppercase;
        padding: 0 48px;
    }
}
</style>
