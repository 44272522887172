<template>
    <div class="room">
        <div class="room__wrapper">
            <div class="room__row room__row--first">
                <div class="room__col room__col--short-info">
                    <div class="room_short-info">
                        <div class="room__type">{{$filters.localize('room')}}</div>
                        <div class="room__short_address">{{room.room_number}}, <br>{{$filters.localize('floor')}} {{room.floor}}</div>
                    </div>
                    <div class="room__photo">
                        <img
                            class="room__photo-img"
                            :class="{pointer: room.images !== null}"
                            @click="showPhotos"
                            :src="require(`@/assets/svg/camera.png`)"
                            alt="camera" />
                        <div class="room__photo-wrap">
                            <div class="room__photo-count">{{room.images === null ? 0 : room.images.length}} {{$filters.localize('photo')}}</div>
                            <button
                                v-if="room.images !== null"
                                class="room__photo-see"
                                @click="showPhotos">{{$filters.localize('see')}}</button>
                        </div>
                    </div>
                </div>

                <div class="room__col room__col--comfort">
                    <div class="room__comfort">
                        <div class="room__comfort-imgs">
                            <img
                                class="room__comfort-beds"
                                v-for="bed in +room.beds"
                                :key="bed"
                                :src="require(`@/assets/svg/comfort.png`)"
                                alt="comfort">
                        </div>
                        <div class="room__comfort-text">{{room.comfortlevel_text[LOCALE]}}</div>
                    </div>
                    <div class="room__comfort-description">{{room.comfort_description[LOCALE]}}</div>
                </div>
            </div>

            <div class="room__row">
                <div class="room__col room__guests">
                    <div class="room__guests-countrys-title">{{$filters.localize('guests')}}</div>
                    <div
                        class="room__guests-not-found"
                        v-if="!room.guests.length"
                        v-html="$filters.localize('noGuests')">
                    </div>
                    <div
                        class="room__guests-item"
                        v-for="(guest, i) in room.guests"
                        :key="i"
                        >
                        <div class="room__guests-item-flag" :data-name="guest.country && guest.country[LOCALE]">
                            <img :src="URL + guest.svg" />
                        </div>
                        <div class="room__guests-item-data">
                            <div class="room__guests-item-country">{{guest.country && guest.country[LOCALE]}}</div>
                            <div class="room__guests-item-faculty">{{guest.faculty[LOCALE]}}, {{guest.course}} {{$filters.localize('course')}}</div>
                        </div>
                    </div>
                </div>

                <div class="room__col room__col--right-down">
                    <div class="room__price">
                        <span class="room__price-cost">{{room.comfortlevelcost}}</span>
                        <span class="room__price-currency">{{$filters.localize(room.currency)}}./{{$filters.localize('monthShort')}}.</span>
                    </div>
                    <button class="room__to-book" disabled="disabled" @click="toBook">
                        {{$filters.localize('toBook')}}
                        <div class="room__to-book-img"></div>
                    </button>
                </div>
            </div>
        </div>
        <div class="room__contract">
            {{$filters.localize('contract')}}
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    name: 'v-room',
    props: {
        room: {
            type: Object,
            default: {}
        },
        corps: {
            type: Object,
            default: {}
        },
    },
    computed: {
        ...mapGetters([
            'LOCALE',
            'CURRENT_STEP',
        ]),
    },
    data() {
        return {
            URL: process.env.VUE_APP_URL,
        }
    },
    methods: {
        ...mapMutations([
            'SET_CURRENT_STEP',
            'SET_IS_POPUP',
            'SET_CURRENT_ROOM',
            'SET_CURRENT_CORPS',
            'SET_SELECTED_ROOM'
        ]),
        async toBook()
        {
			this.SET_SELECTED_ROOM(this.room)
            this.$emit('toggleRooms')
            this.SET_CURRENT_STEP(this.CURRENT_STEP + 1)
        },
        showPhotos()
        {
            if (this.room.images !== null)
            {
                this.SET_CURRENT_ROOM(this.room)
                this.SET_CURRENT_CORPS(this.corps)
                this.SET_IS_POPUP({popup: 'RoomPhotoSliderPopup', value: true})
            }
        }
    }
}
</script>

<style lang="scss">
.room
{
    background-color: #0B1A46;
    border-radius: 5px;

    * {color: #fff;}

    &__wrapper
    {
        padding: 18px 13px;

        @include tablet {padding: 18px 20px;}

        @include desktop
        {
            padding-left: 172px;
            position: relative;
        }
    }

    &__contract {
        @include font-set(11, 1.2, 400, .1em);
        color: #FFBA00;
        padding: 0 15px 18px;

        @include desktop
        {
            text-align: right;
        }
    }

    &__row
    {
        @include tablet {display: flex;}

        &--first
        {
            margin-bottom: 20px;
            @include tablet {margin-bottom: 24px;}
            @include desktop {padding-right: 110px;}
        }
    }

    &__col
    {
        flex: 1 0;

        @include desktop
        {
            &--short-info {flex: 0 1;}
            &--comfort {max-width: 381px;}

            &--right-down
            {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }

    &_short-info
    {
        margin-bottom: 18px;
        display: flex;

        @include tablet
        {
            display: block;
            margin-bottom: 9px;
        }

        @include desktop
        {
            position: absolute;
            top: 18px;
            left: 16px;
            max-width: 140px;
        }
    }

    &__type
    {
        text-transform: capitalize;
        color: #0079C1;
        @include font-set(18, 1rem, 500);
        margin-bottom: 4px;
        margin-right: 13px;

        @include desktop {margin-bottom: 18px;}
    }

    &__short_address
    {
        @include font-set(18, 1.2em, 400);

        br {display: none;}

        @include desktop {br {display: block;}}
    }

    &__photo
    {
        display: flex;
        margin-bottom: 18px;

        img {width: 40px;height: 32px;}

        @include tablet {margin-bottom: 0;}

        @include desktop
        {
            position: absolute;
            top: 21px;
            right: 18px;
        }
    }

    &__photo-img
    {
        &.pointer {cursor: pointer;}
    }

    &__photo-wrap
    {
        margin-left: 10px;
    }

    &__photo-count
    {
        @include font-set(13, 1rem, 400);
    }

    &__photo-see
    {
        @include font-set(14, 1rem, 500);
        color: #0079C1;
        text-decoration: underline;
    }

    &__comfort
    {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @include tablet {margin-bottom: 4px;}
    }

    &__comfort-beds
    {
    }

    &__comfort-text
    {
        color: #0079C1;
        margin-left: 8px;
        @include font-set(18, 1rem, 500);
    }

    &__comfort-description
    {
        @include font-set(14, 1.2rem, 400);
    }

    &__guests
    {
        margin-bottom: 30px;
        @include tablet {margin-bottom: 0px;}
    }

    &__guests-title
    {
        margin-bottom: 18px;

        @include tablet {margin-bottom: 10px;}
    }

    &__guests-countrys-title
    {
        color:  #00A94F;
        @include font-set(18, 1.2rem, 500);
        margin-bottom: 5px;

        &::first-letter {text-transform: uppercase;}
    }

    &__guests-item
    {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;

        &:last-child {margin-bottom: 0;}

        span {@include font-set(13, 1rem, 400);}
    }

    &__guests-item-flag
    {
        margin-right: 14px;
        min-width: 34px;
        max-width: 34px;
        height: 20px;
        position: relative;

        &:hover:before
        {
            content: attr(data-name);
            position: absolute;
            bottom: 50%; right: 5px;
            transform: translate(100%, 100%);
            background: rgba(0, 0, 0, .7);
            color: #fff;
            padding: 5px;
            z-index: 100;
            border-radius: 4px;
            white-space: nowrap;
        }

        img
        {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__guests-not-found
    {
        @include font-set (14, 1.2em, 400);
    }

    &__guests-item-data
    {
        * {@include font-set (14, 1.2em, 400);}
    }

    &__price
    {
        margin-bottom: 18px;

        @include tablet {margin-bottom: 15px;}

        @include desktop {text-align: end;}
    }

    &__price-cost
    {
        margin-right: 10px;
        @include font-set(20, 1rem, 700);
        color: #EE2A24;
        text-transform: lowercase;
    }

    &__price-currency
    {
        @include font-set(20, 1rem, 500);
        color: #EE2A24;
        text-transform: lowercase;
    }

    &__to-book
    {
        padding-top: 2px;
        @include font-set(12, 50px, 700, .1em);
        color: #fff;
        width: 243px;
        background-color: #EE2A24;
        border-radius: 30px;
        text-align: center;
        text-transform: uppercase;
        padding-right: 57px;
        position: relative;
        padding-left: 8px;
        white-space: nowrap;

        @include tablet
        {
            padding-left: 0;
            width: 100%;
            max-width: 286px;
            font-size: 14px;
            line-height: 60px;
        }

        @include desktop
        {
            display: block;
            margin-left: auto;
            min-width: 220px;
        }
    }

    &__to-book-img
    {
        width: 46px;
        height: 46px;
        background-color: #B32017;
        border-radius: 50%;
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);

        &::before
        {
            content: '';
            width: 11px;
            height: 16px;
            background: url('../assets/svg/arrow-empty-r.svg') center / auto no-repeat;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }

        @include tablet
        {
            width: 56px;
            height: 56px;
        }
    }
}

</style>
