<template>
	<div class="v-room-photos">
		<div class="v-room-photos__header">
			<v-back-btn
				@click="$emit('onClose', popupName, false)"
				class="v-room-photos__back"
			/>
			<span>{{CURRENT_CORPS.name ? CURRENT_CORPS.name[LOCALE] : ''}}</span> /
			<span>{{CURRENT_CORPS.type ? CURRENT_CORPS.type[LOCALE] : ''}}</span>
			<v-close-btn
				@click="$emit('onClose', popupName, false)"
				class="v-room-photos__close"
			/>
		</div>
		<div class="v-room-photos__content" @click.self="$emit('onClose', popupName, false)">
			<div class="v-room-photos__container">
				<div class="v-room-photos__slider-top-wrapper">
					<div class="constom-button-big-prev" @click="mainSwiper.slidePrev()"></div>
					<div class="constom-button-big-next" @click="mainSwiper.slideNext()"></div>
					<swiper
						class="v-room-photos__slider-top"
						loop
						:slides-per-view="1"
						:space-between="10"
						:thumbs="{ swiper: thumbsSwiper }"
						@swiper="setMainSwiper"
						@slideChange="onSlideChange"
					>
						<swiper-slide
							v-for="(img, i) in CURRENT_ROOM.images"
							:key="i">
							<img :src="URL + img" alt="room">
						</swiper-slide>

						<div class="v-room-photos__slider-top-nav">
							<div class="constom-button-prev constom-button" @click="mainSwiper.slidePrev()"></div>
							<span>{{currentSlide}}/{{CURRENT_ROOM.images ? CURRENT_ROOM.images.length : 0}}</span>
							<div class="constom-button-next constom-button" @click="mainSwiper.slideNext()"></div>
						</div>
					</swiper>
				</div>
				<swiper
					class="v-room-photos__slider-bottom"
					loop
					:slides-per-view="4"
					:space-between="10"
					:breakpoints="swiperBottomBreakpoints"
					freeMode
					watch-slides-visibility
					watch-slides-progress
					@swiper="setThumbsSwiper"
				>
					<swiper-slide
						v-for="(img, i) in CURRENT_ROOM.images"
						:key="i">
						<img :src="URL + img" alt="room">
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

import vCloseBtn from './v-close-btn';
import vBackBtn from './v-back-btn';

import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/swiper.scss';

SwiperCore.use([Navigation, Thumbs]);

export default {
	name: 'v-room-photos',
	components: {
		Swiper,
		SwiperSlide,
		Navigation,
		vCloseBtn,
		vBackBtn,
	},
	computed: {
		...mapGetters([
			'LOCALE',
			'CURRENT_ROOM',
			'CURRENT_CORPS'
		])
	},
	props: {
		popupName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			URL: process.env.VUE_APP_URL,
			currentPhoto: 0,
			mainSwiper: null,
			thumbsSwiper: null,
			currentSlide: 1,
			swiperBottomBreakpoints: {
				768: {
					spaceBetween: 15
				}
			}
		}
	},
	methods: {
		...mapMutations([
			'CLEAR_CURRENT_ROOM',
			'CLEAR_CURRENT_CORPS',
		]),
		setThumbsSwiper(swiper) {
			console.log('setThumbsSwiper', swiper);
			this.thumbsSwiper = swiper;
		},
		setMainSwiper(swiper) {
			this.mainSwiper = swiper;
		},
		onSlideChange(swiper)
		{
			this.currentSlide = swiper.realIndex + 1
		},
		// moveSlider(direction)
		// {
		// 	this.mainSwiper['slide'+direction]()
		// }
	},
	mounted()
	{
	},
	beforeUnmount()
	{
		this.CLEAR_CURRENT_ROOM();
	}
}
</script>

<style lang="scss">
.v-room-photos
{
	height: 100vh;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	// @include tablet {
	// 	max-width: 800px;
	// }

	&__header
	{
		padding: 0 55px;
		min-height: 80px;
		display: flex;
		align-items: center;
		padding-bottom: 5px;
		background-color: #0B132B;
		span
		{
			white-space: nowrap;
			margin: 0 3px;
			&:first-child {font-weight: bold;}
		}
		@include desktop
		{
			min-height: 50px;
			padding-bottom: 0;
		}
	}

	&__back {
		position: absolute;
		left: 0;
	}
	&__close {
		position: absolute;
		right: 0;
	}

	&__content
	{
		flex: 1 0;
		background-color: #fff;
		padding: 20px 15px;

		@include tablet
		{
			display: flex;
			flex-direction: column;
			padding: 40px 15px;
		}

		@include desktop {
			width: 100%;
			margin: 0 auto;
		}

		// @include widescreen {
		// 	width: 80%;
		// 	margin: 0 auto;
		// }
	}
	&__container
	{
		max-width: 544px;
		margin: 0 auto;

		@include tablet
		{
			flex: 1 0;
			display: flex;
			flex-direction: column;
		}

		@include desktop
		{
			max-width: 600px;
		}
	}
	&__slider-top-wrapper
	{
		position: relative;
		margin-bottom: 10px;
		max-height: 290px;

		@include tablet
		{
			flex: 1 0;
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;
			max-height: 544px;
		}

		@include desktop
		{
			max-height: 600px;
		}

		.constom-button-big-prev,
		.constom-button-big-next
		{
			display: none;
			@include tablet
			{
				display: block;
				position: absolute;
				z-index: 10;
				width: 56px;
				height: 56px;
				background-color: #CDCFD8;
				border-radius: 50%;
				top: 50%;
				&::before,
				&::after
				{
					content: '';
					width: 13px;
					height: 3px;
					background-color: #fff;
					position: absolute;
					left: 50%;
					top: 50%;
				}
				&:active {transform: translateY(2px);}
			}
		}
		.constom-button-big-prev
		{
			left: -96px;
			&::before {transform: translate(-60%, calc(-50% + 4px)) rotate(40deg);}
			&::after {transform: translate(-60%, calc(-50% - 4px)) rotate(-40deg);}
			@include desktop {left: -70px;}
			@include widescreen {left: -84px;}
		}
		.constom-button-big-next
		{
			right: -96px;
			&::before {transform: translate(-30%, calc(-50% + 4px)) rotate(-40deg);}
			&::after {transform: translate(-30%, calc(-50% - 4px)) rotate(40deg);}
			@include desktop {right: -70px;}
			@include widescreen {right: -84px;}
		}
	}
	&__slider-top
	{
		height: 100%;

		.swiper-slide
		{
			height: 290px;

			img
			{
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				top: 0; left: 0;
			}
		}
		@include tablet
		{
			// height: 100%;
			width: 100%;

			.swiper-slide {height: 100%;}
		}
	}
	&__slider-top-nav
	{
		background-color: #0B1A46;
		width: 60px; height: 20px;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 25px;
		overflow: hidden;
		span
		{
			@include font-set(12, 1em, 400);
			transform: translateY(1px);
		}
		.constom-button
		{
			width: 16px;
			height: 100%;
			position: relative;
			&::before,
			&::after
			{
				content: '';
				width: 6px;
				height: 2px;
				background-color: #CDCFD8;
				position: absolute;
				left: 50%;
				top: 50%;
			}
		}
		.constom-button-prev
		{
			&::before {transform: translate(-50%, calc(-50% + 1.5px)) rotate(40deg);}
			&::after {transform: translate(-50%, calc(-50% - 1.5px)) rotate(-40deg);}
		}
		.constom-button-next
		{
			&::before {transform: translate(-50%, calc(-50% + 1.5px)) rotate(-40deg);}
			&::after {transform: translate(-50%, calc(-50% - 1.5px)) rotate(40deg);}
		}
	}
	&__slider-bottom
	{
		.swiper-slide
		{
			height: 65px;
			img
			{
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
		@include tablet
		{
			max-width: 100%;

			.swiper-slide {height: 125px;}
		}
		@include desktop
		{
			.swiper-slide {height: 137px;}
		}
	}
}
</style>
